import React, { useEffect, useState } from 'react';
import { ModalBody } from 'reactstrap';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

import { createTheme, MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useForm from '../CustomHooks/useForm';

const TrainingCompleteModal = (props) => {
  const preferredLanguage = props.preferredLanguage;
  const getTranslation = props.getTranslation;

  const styles = (theme) => ({
    root: {
      margin: 0,
      padding: 20,
      //width: 550,
      //height: 70,
      textAlign: 'center',
    },
    modalBody: {
        padding: 20,
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    deleteTitle: {
      textAlign: 'center',
    },
    deleteAllButton: {
      fontFamily: 'Montserrat, sans-serif',
      width: 'inherit',
      textAlign: 'center',
    },
  });

  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

  const quizRequiredMessaging =
      <ModalBody className={styles.modalBody}>
      <Typography variant="h6" style={{ textAlign: 'center', fontFamily: 'Montserrat, sans-serif' }}>
        {getTranslation('To complete this training, please take the quiz.', preferredLanguage)}
      </Typography>

    <br></br>
    <br />

      <Button
        variant="contained"
        fullWidth="true"
        color="primary"
        onClick={props.pushToQuiz}  
      >
          Proceed to Quiz
      </Button>
  </ModalBody>


  const videoRequiredMessaging =
      <ModalBody className={styles.modalBody}>
      <Typography variant="h6" style={{ textAlign: 'center', fontFamily: 'Montserrat, sans-serif' }}>
        {getTranslation('You have completed this training', preferredLanguage)}
      </Typography>

    <br></br>
    <br />

      <Button
        variant="contained"
        fullWidth="true"
        color="primary"
        onClick={()=> props.history.push('/Dashboard/History')}  
      >
          Return to Portal
      </Button>
  </ModalBody>


  //-----Designation Modal close button toggle-----//

  console.log(props.completionIndicator)

  return (
    <Paper>
      <Dialog
        className={styles.root}
        onClose={props.onClose}
        aria-labelledby="customized-dialog-title"
        open={props.isOpen}
      >
        {/* <DialogTitle id="customized-dialog-title" onClose={props.onClose}></DialogTitle> */}

        {props.completionIndicator === 'Q'
                    ? quizRequiredMessaging
                    : props.completionIndicator === 'V'
                    ? videoRequiredMessaging
                    : ''}

      </Dialog>
    </Paper>
  );
};
export default TrainingCompleteModal;